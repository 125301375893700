/* =============================================================================
   Non-Semantic Helper Classes
   ========================================================================== */

.clearfix,
.centered {
	zoom: 1;
	&:before, &:after {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
	}
}

.transition(@property: all, @speed: 1.5s, @easing: ease) {
	-webkit-transition: @property @speed @easing;
	-moz-transition: @property @speed @easing;
	-ms-transition: @property @speed @easing;
	-o-transition: @property @speed @easing;
	transition: @property @speed @easing;
}