@import "vendor/normalize.less";
@import "helpers.less";
@import "branding.less";
@import "vendor/print.less";

*, *:before, *:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
html, body {
	margin:0;
	padding:0;
}
body {
	color: @white;
	font-smoothing: antialiased;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #000;
}
img {
	max-width: 100%;
}

.centered {
	margin: 0 auto;
	max-width: 1064px;
	padding: 0 20px;
	width:  100%;
}

ul, ol {
	padding:0;
	margin:0;
	list-style:none;
}

header {
	h1, h2 {
		font-size: 72px;
		line-height:1;
	}
	h1 {
		color: @yellow;
		margin:0;
		margin-top:100px;
		font-weight: 300;
	}
	h2 {
		color: @white;
		margin:0;
		font-weight: 300;

	}
}
main {
	font-size: 24px;
	margin-top: 25px;
	margin-bottom:30px;
	line-height: 1.35;
	ol {
		margin-bottom: 35px;
	}
	a {
		color:@yellow;
		text-decoration: none;
		&:hover {
			color:@white;
		}
	}
	h1 {
		font-size: 24px;
		color: @yellow;
		margin-bottom:0;
		font-weight: normal;
	}
	p {
		margin: 0;
	}
	.available {
	    color: @white;
	    text-transform: uppercase;
	    margin-bottom: 35px;
	}
	.realtors .realtor {
    	float: left;
    	width: 33%;
    }
}
footer {
	position: relative;
	margin-bottom: 80px;
	.partners {
		position:absolute;
		bottom: -5px;

		li {
			display: inline-block;
			vertical-align: middle;
			padding-right: 35px;
		}
	}
	.logo {
		float:right;
	}
}
.social {
    position: fixed;
    top: 10%;
    right: 0;
    a {
        width: 40px;
        height: 40px;
        display: block;
        margin-bottom: 10px;
        background-color: @white;
        color: @yellow;
        text-align: center;
        padding-top: 12px;
    }
}
.percentSold {
	position: fixed;
	height: 40px;
	background-color: @white;
	width: 100%;
	bottom: 0;
	color: black;
	line-height:40px;
	font-size: 14px;
	font-weight: bold;
	.amount {
		background-color: @yellow;
		width: 0%;
		text-align:right;
		.transition();
		height:40px;
		p {
			margin:0;
			padding-left:5px;
			padding-right: 25px;
			white-space: nowrap;
		}

		span {
			.transition();

		}

	}
}

@media screen and (max-width: 900px) {
	footer {
		.logo {
			float:none;
			margin:35px 0;
		}
		.partners {
			position:relative;
		}

	}
}
@media screen and (max-width: 815px) {
	header {
		h1, h2 {
			font-size: 60px;
		}
	}
	main {
		font-size: 21px;
		h1 {
			font-size: 21px;
		}
		.realtors .realtor { 
			width: 50%;
		}
	}
}
@media screen and (max-width: 690px) {
	header {
		h1, h2 {
			font-size: 50px;
		}
	}
	main {
		font-size: 19px;
		h1 {
			font-size: 19px;
		}
	}
}
@media screen and (max-width: 571px) {
	header {
		h1, h2 {
			font-size: 40px;
		}
		h1 {
			margin-top: 50px;
		}
	}
	main {
		font-size: 17px;
		h1 {
			font-size: 17px;
		}
		.realtors .realtor { 
			width: 100%;
			margin-bottom: 20px;
		}
	}
	footer {
		.logo {
			width:250px;
		}
		.strata {
			width:60px;
		}
		.tgc {
			width:37px;
		}
		.ksa {
			width: 80px;
		}
		.territorial {
			width: 110px;
		}
		.partners {
			li {
				padding-right:25px;
			}
		}
	}
}
@media screen and (max-width:450px) {
    .social {
        a {
            padding-top: 9px;
        }
    }
	footer {

		.partners {
			li {
				display: block;
				padding: 10px 0;
			}
		}
	}
}
@media screen and (max-width:340px) {
	main {
		font-size: 15px;
		h1 {
			font-size: 15px;
		}
	}
}